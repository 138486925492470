import React from 'react';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { useSelector } from 'react-redux';

import { PromotedBlogData } from '@ha/contentful';

import { useIntl } from 'ha/i18n';
import { normalizeAlgoliaListing } from 'ha/models/Listing/normalizeAlgoliaListing';
import { ListingCardLocations } from 'ha/types/ListingCard';

import {
  BannerCardPlaceholder,
  BannerCard,
} from 'ha/components/Redesign/BannerCard';
import { LazyComponent } from 'ha/components.legacy/LazyComponent';

import { useSearchResultsContentCard } from '../../components/SearchResultsContentCard';
import { SEARCH_LAYOUT_LISTINGS_CONTAINER } from '../../constants';
import { ListingPreviewCardTrackedContainer as ListingPreviewCardTracked } from '../../containers/ListingPreviewCardTracked';
import { getMapViewState } from '../../selectors';
import { MapViewState, SearchListing } from '../../types';

type Props = {
  items: SearchListing[];
};

type BannerProps = {
  contentCard: PromotedBlogData | null;
};

const AVG_LISTING_CARD_HEIGHT_SM_MOBILE = 470; // px
const AVG_LISTING_CARD_HEIGHT_LG_MOBILE = 490; // px

const Banner: React.FC<BannerProps> = ({ contentCard }) => {
  const { T, urlResolver } = useIntl();

  if (contentCard) {
    return <BannerCard {...contentCard} />;
  }

  return (
    <BannerCardPlaceholder
      pathname={urlResolver.getSecurePaymentUrl()}
      title={T('search.page.blog_post_title.48_hour_guarantee')}
      buttonLabel={T('search.page.blog_post_cta.how_it_works')}
    />
  );
};

const SearchResultsListWithBannerCard: React.FC<Props> = ({ items }) => {
  const { contentCard, contentCardIndex } = useSearchResultsContentCard(
    items.length,
  );

  const mapViewState = useSelector(getMapViewState);

  const listingCardPlaceholderHeight =
    typeof window !== 'undefined' && window.screen.width < 400
      ? AVG_LISTING_CARD_HEIGHT_SM_MOBILE
      : AVG_LISTING_CARD_HEIGHT_LG_MOBILE;

  const theme = useTheme();

  const isLargerThanSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  const serverLoadedCards = React.useMemo(() => {
    if (isLargerThanMd) return 8;
    if (isLargerThanSm) return 4;
    return 2;
  }, [isLargerThanMd, isLargerThanSm]);

  // Listing cards are lazy loaded & in the default list view (on Search Page)
  // the scrollableAncestor for the LazyComponent will be `window`
  // but in the `halfMap` view, the page height is fixed and
  // the scrollableAncestor becomes the immediate container of the listings.
  // `cardsContainerElem` is updated based on the `mapViewState`
  const cardsContainerElem = React.useMemo(() => {
    if (typeof window === 'undefined') return 'window';
    if (mapViewState === MapViewState.halfMap) {
      return (
        document.getElementById(SEARCH_LAYOUT_LISTINGS_CONTAINER) || 'window'
      );
    }
    return 'window';
  }, [mapViewState]);

  const renderContentCard = React.useCallback(() => {
    return contentCardIndex >= serverLoadedCards ? (
      <LazyComponent
        key={`banner-${cardsContainerElem?.toString()}`}
        bottomOffset="-30%"
        scrollableAncestor={cardsContainerElem}
        placeholder={
          <div
            style={{
              height: listingCardPlaceholderHeight,
            }}
          />
        }
      >
        <Banner contentCard={contentCard} />
      </LazyComponent>
    ) : (
      <Banner contentCard={contentCard} />
    );
  }, [
    contentCard,
    contentCardIndex,
    serverLoadedCards,
    listingCardPlaceholderHeight,
    cardsContainerElem,
  ]);

  return (
    <React.Fragment>
      {items.map((room, index) => {
        const ssrRender = index >= serverLoadedCards;
        const Card = (
          <ListingPreviewCardTracked
            listingId={room.id}
            room={normalizeAlgoliaListing(room)}
            isLazy={ssrRender}
            withFavorites
            trackLocation={ListingCardLocations.search}
            rentalPeriodType={room.rentalPeriodType}
          />
        );

        return (
          <React.Fragment key={room.id}>
            {ssrRender ? (
              <LazyComponent
                // TODO - Move key to Waypoint (?) @sohammondal
                key={`${room.id}-${cardsContainerElem?.toString()}`}
                bottomOffset="-30%"
                scrollableAncestor={cardsContainerElem}
                placeholder={
                  <div
                    style={{
                      height: listingCardPlaceholderHeight,
                    }}
                  />
                }
              >
                {Card}
              </LazyComponent>
            ) : (
              Card
            )}
            {index + 1 === contentCardIndex && renderContentCard()}
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

export { SearchResultsListWithBannerCard };
