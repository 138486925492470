import React from 'react';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';

import { Fab } from '@hbf/dsl/core';
import { Place, PlaceSlash } from '@hbf/icons/brand-fill';

import { useIntl } from 'ha/i18n';
import { useTrackEvent } from 'ha/modules/Analytics/helpers/TrackEvent';

import { RebrandThemeProvider } from 'ha/modules/ThemeProvider';
import { getCityCanonical } from 'ha/pages/Cities/selectors';
import { toggleMobileMap, updateMapView } from 'ha/pages/Search/actions';
import { getMapViewState } from 'ha/pages/Search/selectors';
import { MapViewState } from 'ha/pages/Search/types';

import { CHANGE_MAP_VIEW_EVENT_NAME } from './constants';

export const SearchMapChipToggle = () => {
  const { T } = useIntl();

  const currentView = useSelector(getMapViewState);
  const cityCanonical = useSelector(getCityCanonical);

  const dispatch = useDispatch();
  const track = useTrackEvent();

  const theme = useTheme();
  const isLargerThanMd = useMediaQuery(theme.breakpoints.up('md'));

  const handleMapViewChange = () => {
    const next =
      currentView === MapViewState.fullMap
        ? MapViewState.noMap
        : MapViewState.fullMap;

    if (isLargerThanMd) {
      track(CHANGE_MAP_VIEW_EVENT_NAME, {
        mapType: next,
        listingcity: cityCanonical,
      });
      dispatch(updateMapView(next));
    } else {
      dispatch(toggleMobileMap());
    }
  };

  return (
    <RebrandThemeProvider>
      <Fab
        color="tertiary"
        size="md"
        startIcon={
          currentView === MapViewState.noMap ? <Place /> : <PlaceSlash />
        }
        onClick={handleMapViewChange}
      >
        {currentView === MapViewState.noMap
          ? T('search_page.cta.open_map')
          : T('search_page.cta.hide_map')}
      </Fab>
    </RebrandThemeProvider>
  );
};
