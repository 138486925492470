import React from 'react';

import { ScrollRestoration } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from 'tss-react/mui';

import { NoSSR } from '@hbf/dsl/legacy';

import { Experiments } from 'ha/constants/experiments';
import { SEARCH } from 'ha/constants/pageNames';

import { getFlexDaysAnalyticsLabel } from 'ha/helpers/getFlexDaysAnalyticsLabel';
import { useMediaQuery } from 'ha/helpers/MediaQuery';

import { playExperiment } from 'ha/modules/Experiments';
import { getUserIdentifier } from 'ha/modules/Experiments/selectors';
import { useFavoriteListings } from 'ha/modules/FavoriteListing/hooks/useFavoriteListings';
import { NavigationHeader } from 'ha/modules/Navigation/NavigationHeader';
import { Page } from 'ha/modules/Page';
import { RebrandThemeProvider } from 'ha/modules/ThemeProvider';
import { LoadableNotFoundPage } from 'ha/pages/NotFound';
import { SearchFiltersDrawerProvider } from 'ha/pages/SearchRedesign/components/SearchFiltersDrawer/SearchFiltersDrawerContext';
import {
  SearchResultsHeaderSticky,
  SearchResultsHeaderStickySkeleton,
} from 'ha/pages/SearchRedesign/components/SearchResultsHeader/SearchResultsHeaderSticky';
import { LoadableSomethingWentWrongPage } from 'ha/pages/SomethingWentWrongPage';
import { LoadableUnsupportedCountriesPage as UnsupportedCountries } from 'ha/pages/UnsupportedCountries';

import { SearchAlertProvider } from '../../SearchRedesign/components/SearchControlsAlert/SearchControlsAlertContext';
import { loadCitiesNearby } from '../actions';
import { getSearchAlert } from '../actions/getSearchAlert';
import { saveSearch } from '../actions/saveSearch';
import { SearchHeader } from '../components/SearchHeader/SearchHeader';
import { SearchPageCrawl } from '../components/SearchPageCrawl';
import { useFiltersAppliedAnalytics } from '../hooks/useFiltersAppliedAnalytics';
import { useRelevantResults } from '../hooks/useRelevantResults';
import { useVisitedMapPins } from '../hooks/useVisitedMapPins';
import { SearchLayout } from '../redesign/components/SearchLayout';
import { SearchFiltersProvider } from '../SearchFiltersProvider';
import {
  getPageName,
  getAnalyticsForListings,
  getErrorState,
  getCityCanonical,
  getCountryCode,
  isNotFound,
  isUnsupportedCountry as isUnsupportedCountrySelector,
  getDateFilter,
  getCategoriesFilter,
  getAnalyticsForFilters,
} from '../selectors';
import { getRankingExperimentVariant } from '../selectors/sortingSelectors';

import { SearchMetaContainer as SearchMeta } from './SearchMeta';

const useStyles = makeStyles()(theme => ({
  headerWrapper: {
    [theme.breakpoints.up('md')]: {
      boxShadow: theme.utils.elevation('elevation/3'),
      zIndex: 1,
    },
  },
}));

const SCROLL_POS_SESSION_KEY = 'ha_search_page_scroll_key';

const SearchRoot: React.FC = () => {
  const { classes } = useStyles();

  const dispatch = useDispatch();
  const { md } = useMediaQuery();

  const { startDate, endDate, flexDays } = useSelector(getDateFilter);
  const kindsFilter = useSelector(getCategoriesFilter);
  const error = Boolean(useSelector(getErrorState));
  const notFound = Boolean(useSelector(isNotFound));
  const isUnsupportedCountry = Boolean(
    useSelector(isUnsupportedCountrySelector),
  );
  const { filterTypes, filterTypesWithValues } = useSelector(
    getAnalyticsForFilters,
  ) as {
    filterTypes?: string[];
    filterTypesWithValues?: object;
  };

  useFiltersAppliedAnalytics();
  useRelevantResults();
  useVisitedMapPins();
  useFavoriteListings();

  const cityCanonical = useSelector(getCityCanonical);
  const countryCode = useSelector(getCountryCode);
  const pageName = useSelector(getPageName);
  const listingsAnalytics = useSelector(getAnalyticsForListings);

  const userIdentifier = useSelector(getUserIdentifier);

  const indexRankingVariant = useSelector(getRankingExperimentVariant);

  React.useEffect(() => {
    if (indexRankingVariant === undefined) return;

    dispatch(
      playExperiment(
        Experiments.TX1828.name,
        indexRankingVariant,
        userIdentifier,
      ),
    );
  }, [indexRankingVariant, dispatch, userIdentifier]);

  React.useEffect(() => {
    dispatch(getSearchAlert());
  }, [dispatch, countryCode, cityCanonical]);

  React.useEffect(() => {
    dispatch(loadCitiesNearby());
  }, [dispatch]);

  React.useEffect(() => {
    dispatch(saveSearch());
  }, [dispatch]);

  // The <ScrollRestoration /> is not working for iPhones, although the scroll position is being stored in the sessionStorage
  // Hence we need this hook to force the scroll to the previously stored position
  React.useEffect(() => {
    try {
      const parsedStoredScroll = JSON.parse(
        sessionStorage.getItem(SCROLL_POS_SESSION_KEY) || '{}',
      ) as Record<string, string>;

      const prevScrollPosition = Number(parsedStoredScroll?.default || 0);

      if (prevScrollPosition) {
        window.scrollTo(0, prevScrollPosition);
      }
    } catch (e) {
      // do nothing
    }
  }, []);

  // memo the traits to avoid re-renders (is used only on page load)
  const pageTraits = React.useMemo(
    () => ({
      ...listingsAnalytics,
      listingcity: cityCanonical,
      countryCode,
      flexibility: getFlexDaysAnalyticsLabel(flexDays),
      filterTypes,
      filterTypesWithValues,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  // memo the remarketingTag to avoid re-renders (is used only on page load)
  const pageRemarketingTag = React.useMemo(
    () => ({
      hrental_pagetype: 'searchresults',
      hrental_startdate: startDate && new Date(startDate).toISOString(),
      hrental_enddate: endDate && new Date(endDate).toISOString(),
      hrental_city: cityCanonical,
      hrental_country: countryCode,
      hrental_search_category: kindsFilter, // TODO: change to categories and map to old values
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  if (error) {
    return <LoadableSomethingWentWrongPage pageName={SEARCH} />;
  }
  if (isUnsupportedCountry) {
    return <UnsupportedCountries />;
  }
  if (notFound) {
    return <LoadableNotFoundPage />;
  }

  return (
    <SearchAlertProvider>
      <SearchFiltersDrawerProvider>
        <SearchFiltersProvider>
          <Page
            key={SEARCH}
            name={pageName}
            category={SEARCH}
            traits={pageTraits}
            remarketingTag={pageRemarketingTag}
            hasFooter={false}
            hasProgressBar={!md}
            useLegacyFonts={false}
            hideCTA
            slots={{
              Header: (
                <RebrandThemeProvider>
                  <div className={classes.headerWrapper}>
                    <NavigationHeader
                      slots={{
                        dynamicIsland: <SearchHeader />,
                        below: (
                          <NoSSR
                            fallback={<SearchResultsHeaderStickySkeleton />}
                          >
                            <SearchResultsHeaderSticky />
                          </NoSSR>
                        ),
                      }}
                    />
                  </div>
                </RebrandThemeProvider>
              ),
            }}
          >
            <ScrollRestoration storageKey={SCROLL_POS_SESSION_KEY} />
            <SearchMeta />
            <SearchPageCrawl />
            <SearchLayout />
          </Page>
        </SearchFiltersProvider>
      </SearchFiltersDrawerProvider>
    </SearchAlertProvider>
  );
};

export { SearchRoot };
