import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@hbf/dsl/core';
import { Place } from '@hbf/icons/brand-fill';
import { ListAlt } from '@hbf/icons/brand-regular';

import { useIntl } from 'ha/i18n';
import { useTrackEvent } from 'ha/modules/Analytics/helpers/TrackEvent';

import { getCityCanonical } from 'ha/pages/Cities/selectors';
import { updateMapView } from 'ha/pages/Search/actions';
import { getMapViewState } from 'ha/pages/Search/selectors';
import { MapViewState } from 'ha/pages/Search/types';

import { CHANGE_MAP_VIEW_EVENT_NAME } from './constants';

export const SearchMapButtonToggle = ({ origin = '' }: { origin: string }) => {
  const { T } = useIntl();

  const currentView = useSelector(getMapViewState);
  const cityCanonical = useSelector(getCityCanonical);

  const dispatch = useDispatch();
  const track = useTrackEvent();

  const handleMapViewChange = () => {
    const next =
      currentView === MapViewState.halfMap
        ? MapViewState.noMap
        : MapViewState.halfMap;

    track(CHANGE_MAP_VIEW_EVENT_NAME, {
      mapType: next,
      listingcity: cityCanonical,
    });
    dispatch(updateMapView(next));
  };

  return (
    <Button
      variant="outlined"
      size="medium"
      color="neutral"
      startIcon={currentView === MapViewState.noMap ? <Place /> : <ListAlt />}
      onClick={handleMapViewChange}
      data-test-locator={`${origin}.SearchMapButtonToggle`}
    >
      {currentView === MapViewState.noMap
        ? T('search.page.corner_image.switch_to_map')
        : T('search.page.corner_image.switch_to_list')}
    </Button>
  );
};
