import { createAction } from 'redux-actions';

import isEmpty from 'lodash-es/isEmpty';

import { SearchAreaValue } from 'ha/types/SearchFilters';

import { Actions } from '../constants';
import {
  getInitialLatitude,
  getInitialLongitude,
  getInitialZoomLevel,
  getIsMobileMapOpen,
  getMapViewState,
  getUseMapAsAFilter,
} from '../selectors/mapSelectors';
import { MapViewState } from '../types';
import { areCoordsEqual } from '../utils/areCoordsEqual';

export const updateMapViewAndParams = createAction(
  Actions.UPDATE_MAP_VIEW,
  payload => payload.mapView,
  meta => ({
    queryParams: {
      view: meta.mapView === MapViewState.noMap ? null : meta.mapView,
    },
    mergeQueryParams: true,
    replaceQueryParams: true,
    skipQueryUpdate: meta.skipQueryUpdate,
  }),
);
export const updateMapView = payload => dispatch => {
  dispatch(
    updateMapViewAndParams({
      mapView: payload,
    }),
  );
};

const updateMapAction = createAction(Actions.UPDATE_MAP, payload => payload);
export const updateMap = payload => dispatch => {
  dispatch(updateMapAction(payload));
};

const updateBoundsAndQueryParams = createAction(
  Actions.UPDATE_BOUNDS,
  payload => payload,
  meta => {
    if (isEmpty(meta?.queryParams)) return {};
    return {
      queryParams: {
        ...meta.queryParams,
      },
      mergeQueryParams: true,
      replaceQueryParams: true,
    };
  },
);
export const onBoundsChanged =
  (bounds, mapCenterAndZoom) => (dispatch, getState) => {
    const state = getState();

    const initialLat = getInitialLatitude(state);
    const initialLng = getInitialLongitude(state);
    const initialZoom = getInitialZoomLevel(state);
    const mapViewState = getMapViewState(state);
    const useMapAsAFilter = getUseMapAsAFilter(state);
    const isMobileMapOpen = getIsMobileMapOpen(state);

    const isInitialState =
      Boolean(initialLat) &&
      areCoordsEqual(mapCenterAndZoom.latitude, initialLat) &&
      Boolean(initialLng) &&
      areCoordsEqual(mapCenterAndZoom.longitude, initialLng) &&
      mapCenterAndZoom.zoom === initialZoom;

    let searchArea = isMobileMapOpen ? null : SearchAreaValue.MapBounds;
    searchArea =
      searchArea || (useMapAsAFilter ? SearchAreaValue.MapBounds : null);

    dispatch(
      updateBoundsAndQueryParams({
        bounds: { ...bounds },
        ...(isInitialState
          ? {}
          : {
              queryParams: {
                page: null,
                searchArea,
                view: mapViewState === MapViewState.noMap ? null : mapViewState,
                lLng: Number(bounds.leftLng.toFixed(6)),
                rLng: Number(bounds.rightLng.toFixed(6)),
                tLat: Number(bounds.topLat.toFixed(6)),
                bLat: Number(bounds.bottomLat.toFixed(6)),
              },
            }),
      }),
    );
  };

const resetMapAction = createAction(Actions.RESET_MAP, null, meta => {
  return {
    queryParams: {
      ...meta.queryParams,
    },
    mergeQueryParams: true,
    replaceQueryParams: true,
    skipQueryUpdate: meta.skipQueryUpdate,
  };
});
export const resetMapAndQueryParams = () => dispatch => {
  dispatch(
    resetMapAction({
      queryParams: {
        searchArea: null,
        lLng: null,
        rLng: null,
        tLat: null,
        bLat: null,
      },
    }),
  );
};

export const updateSearchParams = createAction(Actions.UPDATE_SEARCH_PARAMS);
export const setPreviousParams = createAction(Actions.SET_PREVIOUS_PARAMS);

export const toggleMobileMapAndUpdateParams = createAction(
  Actions.TOGGLE_MOBILE_MAP,
  null,
  meta => ({
    queryParams: {
      view: meta.view,
    },
    mergeQueryParams: true,
    skipQueryUpdate: meta.skipQueryUpdate,
  }),
);
export const toggleMobileMap = () => (dispatch, getState) => {
  const state = getState();
  const isMobileMapOpened = getIsMobileMapOpen(state);

  dispatch(
    toggleMobileMapAndUpdateParams({
      view: !isMobileMapOpened ? MapViewState.fullMap : null,
    }),
  );
};

export const toggleMobileDatePicker = createAction(
  Actions.TOGGLE_MOBILE_DATE_PICKER,
);

export const changePage = createAction(
  Actions.CHANGE_PAGE,
  value => value,
  payload => ({
    queryParams: {
      page: payload && payload.num && payload.num > 1 ? payload.num : null,
    },
    mergeQueryParams: true,
  }),
);

export const disableResultsWithRelevantAvailability = createAction(
  Actions.DISABLE_RESULTS_WITH_RELEVANT_AVAILABILITY,
);

export const notFound = createAction(Actions.LOAD_NOT_FOUND);
export const unsupportedCountry = createAction(
  Actions.LOAD_UNSUPPORTED_COUNTRY,
);
